import { Button, Container, Link, VStack } from '@chakra-ui/react'
import { Input } from '@chakra-ui/react'
import { useState } from 'react'
import urls from './urls'


const UrlShortner = () => {
    const [url, setUrl] = useState("")
    const [shortUrl,setShortUrl] = useState("")
    const [isLoading,setIsLoading] = useState(false)

    const onShortUrl = () => {
        setShortUrl("")
        setIsLoading(true)
        fetch(urls.shortenUrl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                origUrl: url
            })
        }).then(response => response.json()).then(res => {
            setShortUrl(res.shortUrl)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return(
        <VStack>
        <Container padding={3} justifyContent="center   ">
            <Input placeholder='Enter url to shortern' size="lg" value={url} onChange={(event) => {
                setUrl(event.target.value)
            }}/>

            <Button isLoading={isLoading} width="100%" marginTop={3} onClick={onShortUrl}>Shorten</Button>
        </Container>
        {shortUrl ? 
        <Link marginTop={3} color="blue.400" href={shortUrl} target="_blank">{shortUrl}</Link>
        : null}
        </VStack>
    )
}

export default UrlShortner