
import './App.css';
import UrlShortner from './UrlShortner';

function App() {
  return (
   <UrlShortner />
  );
}

export default App;
